import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useFormspark } from "@formspark/use-formspark";

 const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};
const FORMSPARK_FORM_ID = "Yf0uz5sv";
const faqs = [
  {
    question: "What is Astra Seller?",
    answer:
      "Astra Seller is an AI-powered tool that helps Amazon sellers create high-converting product listings. It uses machine learning to analyze your competitors and generate SEO-optimized copy for your product listings.",
  },
  {
    question: "How does Astra Seller work?",
    answer:
      "Astra Seller uses machine learning to analyze your competitors and generate SEO-optimized copy for your product listings.",
  },
  {
    question: "How much does Astra Seller cost?",
    answer:
      "Astra Seller is currently in beta. Early bird pricing starts at $34/month.",
  },
  {
    question: "When will it launch?",
    answer:
      "We are currently in beta. We will be launching a paid version soon!",
  },
];
export default function Index() {
  const pricingSectionRef = React.useRef(null);
  const waitlistRef = React.useRef(null);
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const [email, setEmail] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const handleScrollToPricing = (event) => {
    event.preventDefault();
    pricingSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleWaitlistScroll = (event) => {
    event.preventDefault();
    waitlistRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    await submit({ email });
    setSubmitted(true);
    alert("Form submitted");
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <div>
        <section className="bg-blueGray-50">
          <div className="container mx-auto overflow-hidden">
            <div className="flex items-center justify-between px-4 py-5 bg-blueGray-50">
              <div className="w-auto">
                <div className="flex flex-wrap items-center">
                  <div className="w-auto mr-14">
                    <img
                      src="images/astraLogoResized-removebg-preview.png"
                      alt=""
                      className="w-44"
                    />
                  </div>
                </div>
              </div>
              <div className="w-auto">
                <div className="flex flex-wrap items-center">
                  <div className="w-auto hidden lg:block">
                    <ul className="flex items-center mr-16">
                      <li className="mr-9 font-medium hover:text-gray-700"></li>
                      <li className="mr-9 font-medium hover:text-gray-700"></li>
                      <li className="mr-9 font-medium hover:text-gray-700"></li>
                      <li className="font-medium hover:text-gray-700">
                        <a href="#pricing" onClick={handleScrollToPricing}>
                          Pricing
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-auto hidden lg:block">
                    <div className="inline-block">
                      <button
                        className="py-3 px-5 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                        type="button"
                        onClick={handleWaitlistScroll}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden pt-16">
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap -m-8">
                <div className="w-full md:w-1/2 p-8">
                  <div className="inline-block mb-6 px-2 py-1 font-semibold rounded-full bg-indigo-50">
                    <div className="flex flex-wrap items-center -m-1">
                      <div className="w-auto p-1">
                        <div className="text-sm text-indigo-500">
                          Your AI Powered Assistant&nbsp; 🤖
                        </div>
                      </div>
                      <div className="w-auto p-1"></div>
                    </div>
                  </div>
                  <h1 className="mb-6 text-6xl md:text-8xl lg:text-10xl font-bold font-heading md:max-w-xl leading-none">
                    Rank higher on Amazon With Optimized Product Listings
                  </h1>
                  <p className="mb-11 text-lg text-gray-900 font-medium md:max-w-md">
                    Analyze competitors, generate keywords, and craft
                    SEO-optimized listings so you can rank higher and increase
                    your sales.
                  </p>
                  <div className="flex flex-wrap -m-2.5 mb-20">
                    <div className="w-full md:w-auto p-2.5">
                      <div className="block">
                        <button
                          className="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                          type="button"
                          onClick={handleWaitlistScroll}
                        >
                          Sign Up
                        </button>
                      </div>
                    </div>
                    <div className="w-full md:w-auto p-2.5">
                      <div className="block"></div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-8">
                  <img
                    className="transform hover:-translate-y-16 transition ease-in-out duration-1000"
                    src="images/Group-1000005670.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative pt-28 pb-24 bg-white overflow-hidden">
          <div className="relative z-10 container px-4 mx-auto">
            <p className="mb-6 text-sm text-indigo-600 text-center font-semibold uppercase tracking-px">
              HOW ASTRA SELLER WORKS
            </p>
            <h2 className="mb-20 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight">
              Create powerful, high-converting listings
            </h2>
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-1/2 lg:w-1/4 p-8">
                <div className="text-center">
                  <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        width={22}
                        height={22}
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.75 9.16658H19.25M6.41667 13.7499H7.33333M11 13.7499H11.9167M5.5 17.4166H16.5C18.0188 17.4166 19.25 16.1854 19.25 14.6666V7.33325C19.25 5.81447 18.0188 4.58325 16.5 4.58325H5.5C3.98122 4.58325 2.75 5.81447 2.75 7.33325V14.6666C2.75 16.1854 3.98122 17.4166 5.5 17.4166Z"
                          stroke="#4F46E5"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200" />
                  </div>
                  <div className="md:max-w-xs mx-auto">
                    <h3 className="mb-4 font-heading text-xl font-bold font-heading leading-normal">
                      Enter Your ASIN
                    </h3>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      Enter you products title or ASIN
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-8">
                <div className="text-center">
                  <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                          stroke="#4F46E5"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200" />
                  </div>
                  <div className="md:max-w-xs mx-auto">
                    <h3 className="mb-4 font-heading text-xl font-bold font-heading leading-normal">
                      Spy On Competitors
                    </h3>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      Select up to 5 relevant competitor product listings to
                      analyze
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-8">
                <div className="text-center">
                  <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        width={19}
                        height={16}
                        viewBox="0 0 19 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.66717 2.54545V10.0455C8.66717 10.7841 8.52335 11.489 8.23571 12.1602C7.94807 12.8313 7.55922 13.4119 7.06916 13.902C6.5791 14.392 5.99849 14.7809 5.32733 15.0685C4.65616 15.3562 3.95126 15.5 3.21262 15.5H2.53081C2.34615 15.5 2.18635 15.4325 2.0514 15.2976C1.91646 15.1626 1.84899 15.0028 1.84899 14.8182V13.4545C1.84899 13.2699 1.91646 13.1101 2.0514 12.9751C2.18635 12.8402 2.34615 12.7727 2.53081 12.7727H3.21262C3.96547 12.7727 4.60822 12.5064 5.14089 11.9737C5.67356 11.4411 5.9399 10.7983 5.9399 10.0455V9.70455C5.9399 9.42046 5.84047 9.17898 5.6416 8.98011C5.44274 8.78125 5.20126 8.68182 4.91717 8.68182H2.53081C1.96262 8.68182 1.47967 8.48295 1.08194 8.08523C0.684215 7.6875 0.485352 7.20455 0.485352 6.63636V2.54545C0.485352 1.97727 0.684215 1.49432 1.08194 1.09659C1.47967 0.698864 1.96262 0.5 2.53081 0.5H6.62172C7.1899 0.5 7.67285 0.698864 8.07058 1.09659C8.46831 1.49432 8.66717 1.97727 8.66717 2.54545ZM18.2126 2.54545V10.0455C18.2126 10.7841 18.0688 11.489 17.7812 12.1602C17.4935 12.8313 17.1047 13.4119 16.6146 13.902C16.1246 14.392 15.5439 14.7809 14.8728 15.0685C14.2016 15.3562 13.4967 15.5 12.7581 15.5H12.0763C11.8916 15.5 11.7318 15.4325 11.5969 15.2976C11.4619 15.1626 11.3944 15.0028 11.3944 14.8182V13.4545C11.3944 13.2699 11.4619 13.1101 11.5969 12.9751C11.7318 12.8402 11.8916 12.7727 12.0763 12.7727H12.7581C13.5109 12.7727 14.1537 12.5064 14.6863 11.9737C15.219 11.4411 15.4854 10.7983 15.4854 10.0455V9.70455C15.4854 9.42046 15.3859 9.17898 15.1871 8.98011C14.9882 8.78125 14.7467 8.68182 14.4626 8.68182H12.0763C11.5081 8.68182 11.0251 8.48295 10.6274 8.08523C10.2297 7.6875 10.0308 7.20455 10.0308 6.63636V2.54545C10.0308 1.97727 10.2297 1.49432 10.6274 1.09659C11.0251 0.698864 11.5081 0.5 12.0763 0.5H16.1672C16.7354 0.5 17.2183 0.698864 17.616 1.09659C18.0138 1.49432 18.2126 1.97727 18.2126 2.54545Z"
                          fill="#4F46E5"
                        />
                      </svg>
                    </div>
                    <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200" />
                  </div>
                  <div className="md:max-w-xs mx-auto">
                    <h3 className="mb-4 font-heading text-xl font-bold font-heading leading-normal">
                      Generate/Import Keywords
                    </h3>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      Import you own keywords or use our generated keywords
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-8">
                <div className="text-center">
                  <div className="relative z-10 bg-indigo-600 w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        width={22}
                        height={16}
                        viewBox="0 0 22 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.5734 0.93934C21.1591 1.52513 21.1591 2.47487 20.5734 3.06066L8.57336 15.0607C7.98757 15.6464 7.03782 15.6464 6.45204 15.0607L0.452035 9.06066C-0.133751 8.47487 -0.133751 7.52513 0.452035 6.93934C1.03782 6.35355 1.98757 6.35355 2.57336 6.93934L7.5127 11.8787L18.452 0.93934C19.0378 0.353553 19.9876 0.353553 20.5734 0.93934Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="md:max-w-xs mx-auto">
                    <h3 className="mb-4 font-heading text-xl font-bold font-heading leading-normal">
                      Produce Professional Copy!
                    </h3>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      Let the AI do its magic and produce stellar SEO listings!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container px-4 mx-auto mt-32">
            <div className="flex flex-wrap xl:items-center -m-8">
              <div className="w-full md:w-1/2 p-8">
                <img
                  className="shadow-4xl"
                  src="https://static.shuffle.dev/uploads/files/72/727d3832b2c2a6008a5b1c8429bf0476c82a2f4e/landingImage1.png"
                  alt=""
                />
              </div>
              <div className="w-full md:w-1/2 p-8">
                <div className="md:max-w-md">
                  <div className="flex flex-wrap -m-4">
                    <div className="w-auto p-4">
                      <div className="flex flex-wrap -m-2">
                        <div className="w-auto p-2">
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.66669 5.83329C4.66669 5.18896 5.18902 4.66663 5.83335 4.66663H22.1667C22.811 4.66663 23.3334 5.18896 23.3334 5.83329V8.16663C23.3334 8.81096 22.811 9.33329 22.1667 9.33329H5.83335C5.18902 9.33329 4.66669 8.81096 4.66669 8.16663V5.83329Z"
                              stroke="#6366F1"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.66669 15.1666C4.66669 14.5223 5.18902 14 5.83335 14H12.8334C13.4777 14 14 14.5223 14 15.1666V22.1666C14 22.811 13.4777 23.3333 12.8334 23.3333H5.83335C5.18902 23.3333 4.66669 22.811 4.66669 22.1666V15.1666Z"
                              stroke="#6366F1"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M18.6667 15.1666C18.6667 14.5223 19.189 14 19.8334 14H22.1667C22.811 14 23.3334 14.5223 23.3334 15.1666V22.1666C23.3334 22.811 22.811 23.3333 22.1667 23.3333H19.8334C19.189 23.3333 18.6667 22.811 18.6667 22.1666V15.1666Z"
                              stroke="#6366F1"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <h3 className="text-lg font-semibold">
                            Competitor Insights
                          </h3>
                        </div>
                        <div className="w-full p-2">
                          <div className="pb-8 border-b">
                            <p className="text-gray-600 font-medium leading-relaxed">
                              Gain insights into your competitors copy
                              strategies and find out which keywords are driving
                              the most sales in your niche.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-auto p-4">
                      <div className="flex flex-wrap -m-2">
                        <div className="w-auto p-2">
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.83333 14.0001H22.1667M5.83333 14.0001C4.54467 14.0001 3.5 12.9554 3.5 11.6667V7.00008C3.5 5.71142 4.54467 4.66675 5.83333 4.66675H22.1667C23.4553 4.66675 24.5 5.71142 24.5 7.00008V11.6667C24.5 12.9554 23.4553 14.0001 22.1667 14.0001M5.83333 14.0001C4.54467 14.0001 3.5 15.0448 3.5 16.3334V21.0001C3.5 22.2887 4.54467 23.3334 5.83333 23.3334H22.1667C23.4553 23.3334 24.5 22.2887 24.5 21.0001V16.3334C24.5 15.0448 23.4553 14.0001 22.1667 14.0001M19.8333 9.33342H19.845M19.8333 18.6667H19.845"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <h3 className="text-lg font-semibold">
                            Generate Keywords
                          </h3>
                        </div>
                        <div className="w-full p-2">
                          <div className="pb-8 border-b">
                            <p className="text-gray-600 font-medium leading-relaxed">
                              Gain access to your competitors/ import your own
                              keywords or use our keyword generator to find the
                              best keywords for your product.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-auto p-4">
                      <div className="flex flex-wrap -m-2">
                        <div className="w-auto p-2">
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.5 11.6666H24.5M8.16667 17.4999H9.33333M14 17.4999H15.1667M7 22.1666H21C22.933 22.1666 24.5 20.5996 24.5 18.6666V9.33325C24.5 7.40026 22.933 5.83325 21 5.83325H7C5.067 5.83325 3.5 7.40026 3.5 9.33325V18.6666C3.5 20.5996 5.067 22.1666 7 22.1666Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <h3 className="text-lg font-semibold">
                            Copy That Drives Sales
                          </h3>
                        </div>
                        <div className="w-full p-2">
                          <p className="text-gray-600 font-medium leading-relaxed">
                            Easily generate copy for your Amazon listing without
                            the need to hire a professional. create optimized
                            copy that will help boost your online visibility and
                            drive more sales.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-32 bg-blueGray-50 overflow-hidden">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap items-center -m-8">
              <div className="w-full md:w-1/2 p-8">
                <h2 className="mb-6 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">
                  Professional Copywriting with the Click of a Button
                </h2>
                <p className="font-sans text-lg text-gray-900 leading-relaxed md:max-w-lg">
                  Experience the power of professional copywriting made
                  effortless with AstraSeller. Craft compelling Amazon listings
                  seamlessly with our AI-enhanced system, transforming your
                  product descriptions into high-converting narratives at the
                  click of a button.
                </p>
              </div>
              <div className="w-full md:w-1/2 p-8">
                <img
                  className="transform hover:-translate-y-16 transition ease-in-out duration-1000"
                  src="images/landingImage2.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="relative pt-24 pb-28 bg-blueGray-50 overflow-hidden">
          <img
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
            src="flaro-assets/images/faqs/gradient.svg"
            alt=""
          />
          <div className="relative z-10 container px-4 mx-auto">
            <div className="md:max-w-4xl mx-auto">
              <p className="mb-7 text-sm text-indigo-600 text-center font-semibold uppercase tracking-px">
                Have any questions?
              </p>
              <h2 className="mb-16 text-6xl md:text-8xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none">
                Frequently Asked Questions
              </h2>
              <div className="mb-11 flex flex-wrap -m-1">
                {faqs.map((faq) => (
                  <Accordian
                    key={faq.question}
                    question={faq.question}
                    answer={faq.answer}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Sign up form */}
        <section
          className="relative pt-28 pb-36 bg-white overflow-hidden"
          ref={pricingSectionRef}
          id="pricing"
        >
          <div className="relative z-10 container px-4 mx-auto">
            <h2
              className="mb-6 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none md:max-w-xl mx-auto text-center"
              ref={waitlistRef}
              id="waitlist"
            >
              Get Early Access
            </h2>
            <p className="text-lg text-gray-900 text-center font-medium leading-normal md:max-w-lg mx-auto">
              Join our waitlist and get access to AstraSeller for a discounted
              early-bird prices
            </p>
            {!submitted ? (
              <form
                onSubmit={onSubmit}
                className="flex justify-center mt-5 mb-10"
              >
                <div className="flex items-center w-full max-w-md mb-3 seva-fields formkit-fields">
                  <div className="relative w-full mr-3 formkit-field">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                      </svg>
                    </div>
                    <input
                      id="member_email"
                      className="formkit-input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="email_address"
                      aria-label="Email Address"
                      placeholder="Your email address..."
                      required={true}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    data-element="submit"
                    disabled={submitting}
                    className="px-5 py-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Join
                  </button>
                </div>
              </form>
            ) : (
              <div className="flex w-full justify-center py-10 font-bold text-xl">
                Thank you for joining the waitlist!
              </div>
            )}

            <div className="md:max-w-4xl mx-auto">
              <div className="flex flex-wrap -m-5 justify-center">
                <div className="w-full md:w-1/2 p-5">
                  <div
                    className="bg-white bg-opacity-90 border border-blueGray-100 rounded-4xl shadow-9xl"
                    style={{ backdropFilter: "blur(46px)" }}
                  >
                    <div className="border-b border-blueGray-100">
                      <div className="py-7 px-9">
                        <h3 className="mb-3 text-xl text-gray-900 font-bold leading-snug text-center">
                          Early Bird Pricing
                        </h3>
                      </div>
                    </div>
                    <div className="pt-8 pb-9 px-9">
                      <p className="mb-6 text-gray-600 font-medium leading-relaxed">
                        Features included:
                      </p>
                      <ul className="mb-11">
                        <li className="mb-4 flex items-center">
                          <svg
                            className="mr-2"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                              stroke="#4F46E5"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="font-semibold leading-normal">
                            Unlimited Listings Per Month
                          </p>
                        </li>
                        <li className="mb-4 flex items-center">
                          <svg
                            className="mr-2"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                              stroke="#4F46E5"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="font-semibold leading-normal">
                            Competitor Analysis
                          </p>
                        </li>
                        <li className="mb-4 flex items-center">
                          <svg
                            className="mr-2"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                              stroke="#4F46E5"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="font-semibold leading-normal">
                            Keywords Uploader
                          </p>
                        </li>
                        <li className="mb-4 flex items-center">
                          <svg
                            className="mr-2"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                              stroke="#4F46E5"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="font-semibold leading-normal">
                            Keywords Research
                          </p>
                        </li>
                        <li className="flex items-center">
                          <svg
                            className="mr-2"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                              stroke="#4F46E5"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <p className="font-semibold leading-normal">
                            Bulk Export
                          </p>
                        </li>
                      </ul>
                      <p className="mb-6 text-xl text-gray-500 font-semibold leading-normal">
                        <span>Starting from</span>
                        <span className="text-gray-900 ml-2">$34/mo</span>
                      </p>
                      <div className="">
                        <button
                          className="py-4 px-10 text-white font-semibold rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200 w-full"
                          type="button"
                          onClick={handleWaitlistScroll}
                        >
                          Join Waitlist
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full py-64 bg-indigo-600" />
        </section>
      </div>
    </React.Fragment>
  );
}

const Accordian = ({ question, answer }) => {
  const [open, setOpen] = React.useState(false);
  const borderColorVariants = {
    open: "border-indigo-600",
    closed: "border-gray-200 hover:border-gray-300",
  };
  const closeIcon = (
    <svg
      className="relative top-1"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16732 12.5L10.0007 6.66667L15.834 12.5"
        stroke="#4F46E5"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
  const openIcon = (
    <svg
      className="relative top-1"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 6.75L9 12L3.75 6.75"
        stroke="#18181B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
  return (
    <div className="w-full p-1">
      <div
        className="cursor-pointer transition ease-in-out duration-200"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div
          className={`py-7 px-8 bg-white bg-opacity-60 border-2 ${
            open ? borderColorVariants["open"] : borderColorVariants["closed"]
          } rounded-2xl shadow-10xl`}
        >
          <div className="flex flex-wrap justify-between -m-2">
            <div className="flex-1 p-2">
              <h3 className="mb-4 text-lg font-semibold leading-normal">
                {question}
              </h3>
              {open && <p className="text-gray-600 font-medium">{answer}</p>}
            </div>
            <div className="w-auto p-2">{open ? closeIcon : openIcon}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
